import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { SoftwareContext } from '../Context/SoftwareContext.js';
import "../Components/CategoryList.css";

const categories = [
  "Browsers", "Desktop", "File Sharing", "Games", "Learning",
  "Maps & Travel", "Multimedia", "Personalization",
  "Security", "Social", "System Tuning & Utilities"
];

function CategoryList() {
  const { setSelectedCategory } = useContext(SoftwareContext);

  return (
    <div className="category-list">
      <ul className="list-group">
        <h5>Categories</h5>
        <hr />
        {categories.map(category => (
          <li key={category} className="categoryList_categorytext_li">
            <Link className='link_categorylist' to={`/category/${category.toLowerCase()}`} onClick={() => setSelectedCategory(category)}>
              <span><p className='categoryList_categorytext'>{category}</p></span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default CategoryList;
