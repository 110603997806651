import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { SoftwareContext } from '../Context/SoftwareContext.js';

function SearchResults() {
  const { searchResults } = useContext(SoftwareContext);
  const [currentPage, setCurrentPage] = useState(1);
  const resultsPerPage = 10;
  const navigate = useNavigate();

  const indexOfLastResult = currentPage * resultsPerPage;
  const indexOfFirstResult = indexOfLastResult - resultsPerPage;
  const currentResults = searchResults.slice(indexOfFirstResult, indexOfLastResult);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="search-results">
      <button onClick={() => navigate(-1)} className="btn btn-secondary mb-3">Back</button>
      <h2>Search Results</h2>
      <ul className="list-group">
        {currentResults.map(item => (
          <li key={item.id} className="list-group-item">
            <Link to={`/software/${item.id}`}>{item.name}</Link>
          </li>
        ))}
      </ul>
      <nav>
        <ul className="pagination">
          {Array(Math.ceil(searchResults.length / resultsPerPage)).fill().map((_, i) => (
            <li key={i + 1} className="page-item">
              <button onClick={() => paginate(i + 1)} className="page-link">
                {i + 1}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
}

export default SearchResults;
