import React from 'react';
import "./PrivacyPolicy.css";
const PrivacyPolicy = () => {
  return (
    <div className='privacy_poliy_page_container'>
      <h1>Privacy Policy</h1>
      <p>Last updated: June 8, 2024</p>

      <p>Welcome to our website! This privacy policy explains how we handle your personal information when you use our website to read software reviews, access technology-related information, and download software or apps via links to official websites.</p>

      <h2>1. Information We Collect</h2>
      <p>We collect the following types of information:</p>
      <ul>
        <li><strong>Personal Information:</strong> If you register on our website, we may collect personal information such as your name, email address, and other contact details.</li>
        <li><strong>Usage Data:</strong> We collect information on how you access and use our website. This includes your IP address, browser type, pages visited, time spent on pages, and other diagnostic data.</li>
        <li><strong>Cookies:</strong> We use cookies and similar tracking technologies to monitor activity on our website and store certain information.</li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <p>We use the collected information for various purposes:</p>
      <ul>
        <li>To provide and maintain our website</li>
        <li>To notify you about changes to our website</li>
        <li>To allow you to participate in interactive features when you choose to do so</li>
        <li>To provide customer support</li>
        <li>To gather analysis or valuable information to improve our website</li>
        <li>To monitor the usage of our website</li>
        <li>To detect, prevent, and address technical issues</li>
      </ul>

      <h2>3. Cookies</h2>
      <p>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites you visit and are stored on your device's internal memory.</p>
      <p>You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of our website.</p>

      <h2>4. Third-Party Links</h2>
      <p>Our website contains links to external sites that are not operated by us. If you click on a third-party link, you will be directed to that site. We strongly advise you to review the privacy policy of every site you visit.</p>
      <p>We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>

      <h2>5. Security of Your Information</h2>
      <p>We value your trust in providing us your personal information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>

      <h2>6. Changes to This Privacy Policy</h2>
      <p>We may update our privacy policy from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new privacy policy on this page.</p>
      <p>This policy is effective as of June 8, 2024.</p>

      <h2>Contact Us</h2>
      <p>If you have any questions or suggestions about our privacy policy, do not hesitate to contact us at info@softwaresmarket.com.</p>
    </div>
  );
};

export default PrivacyPolicy;
