import React, { createContext, useState, useEffect } from 'react';
import data from '../data.json';

export const SoftwareContext = createContext();

export const SoftwareProvider = ({ children }) => {
  const [softwareData, setSoftwareData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [popularSoftware, setPopularSoftware] = useState([]);

  useEffect(() => {
    setSoftwareData(data);
    // Assuming 'popularity' field to determine popular software
    setPopularSoftware(data.slice(0, 10));
  }, []);

  const value = {
    softwareData,
    selectedCategory,
    setSelectedCategory,
    searchResults,
    setSearchResults,
    popularSoftware
  };

  return (
    <SoftwareContext.Provider value={value}>
      {children}
    </SoftwareContext.Provider>
  );
};
