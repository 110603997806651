import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { SoftwareContext } from '../Context/SoftwareContext.js';
import "../Components/PopularSoftware.css";
function PopularSoftware() {
  const { popularSoftware } = useContext(SoftwareContext);

  return (
    <div className="popular-software">
      <h4>Popular Software</h4>
      <ul className="list-group">
        {popularSoftware.map(item => (
          <li key={item.id} className="list-group-item">
            <Link className='popularSoftwareLinkText' to={`/software/${item.id}`}><img src={item.imageLogo} alt="logo" /> {item.name}  |  {`${item.tryORFreeORpaid}`}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default PopularSoftware;
