import React from 'react';
import "./LegalInformation.css"
const LegalInformation = () => {
  return (
    <div className='LegalInformation_container'>
      <h1>Legal Information</h1>
      <p>Last updated: June 8, 2024</p>

      <h2>1. Disclaimers</h2>
      <p>
        The information provided by our website is for general informational purposes only. All information on the site is provided in good faith, however, we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the site. Under no circumstance shall we have any liability to you for any loss or damage of any kind incurred as a result of the use of the site or reliance on any information provided on the site. Your use of the site and your reliance on any information on the site is solely at your own risk.
      </p>

      <h2>2. Copyright Information</h2>
      <p>
        All content and materials on this website, including text, graphics, logos, images, and software, are the property of our website or our content suppliers and are protected by international copyright laws. The compilation of all content on this site is the exclusive property of our website and is protected by international copyright laws. Unauthorized use and/or duplication of this material without express and written permission from this site’s author and/or owner is strictly prohibited.
      </p>

      <h2>3. Terms of Use</h2>
      <p>
        By accessing and using this website, you accept and agree to be bound by the terms and provisions of this agreement. In addition, when using this website's particular services, you shall be subject to any posted guidelines or rules applicable to such services. Any participation in this service will constitute acceptance of this agreement. If you do not agree to abide by the above, please do not use this service.
      </p>

      <h3>Prohibited Uses</h3>
      <p>
        You may use the website only for lawful purposes and in accordance with the terms. You agree not to use the website:
      </p>
      <ul>
        <li>In any way that violates any applicable national or international law or regulation.</li>
        <li>For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content or otherwise.</li>
        <li>To transmit, or procure the sending of, any advertising or promotional material, including any “junk mail”, “chain letter”, “spam”, or any other similar solicitation.</li>
        <li>To impersonate or attempt to impersonate the website, a website employee, another user, or any other person or entity.</li>
        <li>To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the website, or which, as determined by us, may harm or offend the website or users of the website or expose them to liability.</li>
      </ul>

      <h2>4. Third-Party Links</h2>
      <p>
        Our website may contain links to third-party websites or services that are not owned or controlled by us. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or services. We do not warrant the offerings of any of these entities/individuals or their websites.
      </p>
      <p>
        You acknowledge and agree that we shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods, or services available on or through any such third-party websites or services. We strongly advise you to read the terms and conditions and privacy policies of any third-party websites or services that you visit.
      </p>

      <h2>5. Changes to This Legal Information</h2>
      <p>
        We may update our legal information from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new legal information on this page.
      </p>
      <p>
        This policy is effective as of June 8, 2024.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions or suggestions about our legal information, do not hesitate to contact us at info@softwaresmarket.com.
      </p>
    </div>
  );
};

export default LegalInformation;
