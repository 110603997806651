import React from 'react';
import CategoryList from '../Components/CategoryList';
import PopularSoftware from '../Components/PopularSoftware';

function Home() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-3">
          <CategoryList />
        </div>
        <div className="col-md-9">
          <PopularSoftware />
        </div>
      </div>
    </div>
  );
}

export default Home;
