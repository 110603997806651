import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from '../src/Components/Navbar.js';
import Footer from '../src/Components/Footer';
import Home from './Pages/Home';
import SearchResults from './Pages/SearchResults';
import SoftwareDetail from './Components/SoftwareDetail';
import TermsAndConditions from './Pages/TermsAndConditions.js';
import PrivacyPolicy from "./Pages/PrivacyPolicy.js";
import LegalInformation from "./Pages/LegalInformation.js";
import ContactUs from "./Pages/ContactUs.js";
import { SoftwareProvider } from './Context/SoftwareContext.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';

function App() {
  return (
    <SoftwareProvider>
      <Router>
        <div className="App">
          this site is underMaintainance. Launch Soon
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/category/:category" element={<Home />} />
            <Route path="/search" element={<SearchResults />} />
            <Route path="/software/:id" element={<SoftwareDetail />} />
            <Route path="/contact" element={<ContactUs />} />
            {/* Add routes for footer links */}
            <Route path="/termsandconditions" element={<TermsAndConditions />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/legalinformation" element={<LegalInformation />} />
            {/* <Route path="/cookies" element={<Cookies />} /> */}
            {/* <Route path="/cookie-settings" element={<CookieSettings />} /> */}
          </Routes>
          <Footer />
        </div>
      </Router>
    </SoftwareProvider>
  );
}

export default App;
