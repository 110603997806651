import React from 'react';
import { Link } from 'react-router-dom';
import "../Components/Footer.css";

function Footer() {
  return (
    <footer className="text-center text-lg-start mainContainerFooter">
      <div className="container p-4">
        <div className="row">
          <div className="col-lg-6 col-md-12 mb-4">
            <h6 className="text-uppercase">Links</h6>
            <ul className="list-unstyled footerText">
              <li>
                <Link className='FooterLinkText' to="/termsandconditions">Terms and Conditions</Link>
              </li>
              <li>
                <Link className='FooterLinkText' to="/privacypolicy">Privacy Policy</Link>
              </li>
              <li>
                <Link className='FooterLinkText' to="/legalinformation">Legal Information</Link>
              </li>
              {/* <li>
                <Link className='FooterLinkText' to="/cookies">Cookie Policy</Link>
              </li>
              <li>
                <Link className='FooterLinkText' to="/cookie-settings">Cookie Settings</Link>
              </li> */}
            </ul>
          </div>

          <div className="col-lg-6 col-md-12 mb-4">
            <h6 className="text-uppercase">Contact Us</h6>
            <ul className="list-unstyled">
              <li>
                <Link className='FooterLinkText' to=""><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
</svg> info@softwaresmarket.com</Link>
              </li>
            </ul>
          </div>
        </div>
      <hr />
        <div>
    <p className='copyrightLineText'>Copyright © 2024 "softwaresmarket.com"  all rights reserved.</p>
    
        </div>



      </div>
    </footer>
  );
}

export default Footer;
