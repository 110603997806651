import React from 'react';
import "./TermsAndConditions.css";

const TermsAndConditions = () => {
  return (
    <div className="terms-and-conditions">
      <h1>Terms and Conditions</h1>
      <p>Last updated: June 8, 2024</p>
      
      <h2>1. Acceptance of Terms</h2>
      <p>
        By accessing or using our website ("Service"), you agree to be bound by these Terms and Conditions ("Terms"). If you disagree with any part of the terms, then you may not access the Service.
      </p>

      <h2>2. Changes to Terms</h2>
      <p>
        We reserve the right to modify or replace these Terms at any time. Your continued use of the Service after any changes signifies your acceptance of the new Terms.
      </p>

      <h2>3. Use of the Service</h2>
      <p>
        Our Service allows you to read reviews on software, gain information about technologies, and download software and apps through provided links. These links will redirect you to official websites.
      </p>

      <h2>4. User Conduct</h2>
      <p>
        You agree to use the Service only for lawful purposes and in a way that does not infringe the rights of, restrict, or inhibit anyone else's use and enjoyment of the Service.
      </p>

      <h2>5. Content</h2>
      <p>
        All content provided on the Service is for informational purposes only. We make no representations as to the accuracy or completeness of any information on this site or found by following any link on this site.
      </p>

      <h2>6. Links to Other Websites</h2>
      <p>
        Our Service may contain links to third-party websites or services that are not owned or controlled by us. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or services.
      </p>

      <h2>7. Termination</h2>
      <p>
        We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.
      </p>

      <h2>8. Limitation of Liability</h2>
      <p>
        In no event shall softwaresmarket.com, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use the Service; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; (iii) any interruption or cessation of transmission to or from the Service.
      </p>

      <h2>9. Governing Law</h2>
      <p>
        These Terms shall be governed and construed in accordance with the laws of India, without regard to its conflict of law provisions.
      </p>

      <h2>10. Contact Us</h2>
      <p>
        If you have any questions about these Terms, please contact us at info@softwaresmarket.com.
      </p>
    </div>
  );
}

export default TermsAndConditions;
